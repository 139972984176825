import { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import Swal from "sweetalert2";
import Loading from "components/Loading/Component";
import Error from "components/Error/Page";

import {
	Col,
	Row,
	Layout,
	Typography,
	Button,
	Card,
	Form,
	Input,
	notification,
	Upload,
	DatePicker,
} from "antd";

import { useImmer } from "use-immer";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";

function User({ id, business_id }) {
	const [disabled, setDisabled] = useState(true);
	const [state, setState] = useImmer(null);

	const [saveAction, { loading: loadingSave }] = useMutation(
		gql`
         mutation BusinessCertificate($business_id: String!, $input: BusinessCertificateInput!, $id: String!) {
            BusinessCertificate(business_id: $business_id, input: $input, id: $id) {
               certificate
               certificate_expiration_date
               certificate_password
            }
         }
      `
	);

	const { loading, error, refetch } = useQuery(
		gql`
      query BusinessCertificate($business_id: String!, $id: String!) {
         BusinessCertificate(business_id: $business_id, id: $id) {
            certificate
            certificate_expiration_date
            certificate_password
         }
      }
   `,
		{
			fetchPolicy: "no-cache",
			variables: {
				id,
				business_id,
			},
			onCompleted: (data) => {
				if (data.BusinessCertificate) {
					let { certificate, ...request } = data.BusinessCertificate;

					if (request.certificate_expiration_date)
						request.certificate_expiration_date = moment(
							request.certificate_expiration_date
						);

					setState({
						...request,
						certificate_url: certificate,
					});
				} else {
					setState({});
				}
			},
		}
	);

	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			type: "error",
			confirmButtonText: "OK",
		});
	};

	const save = async () => {
		let obj = {
			...state,
		};

		delete obj.certificate_url;
		if (obj.certificate_expiration_date)
			obj.certificate_expiration_date = obj.certificate_expiration_date
				.startOf("day")
				.toISOString();

		const { error } = await saveAction({
			variables: {
				input: {
					...obj,
				},
				id,
				business_id,
			},
		});
		if (error) {
			ErrorDialog(
				"Não foi possível salvar as informações no momento. Tente novamente mais tarde."
			);
		} else {
			notification.success({
				message: "Sucesso",
				description: "Informações salvas com sucesso",
			});
			refetch();
		}
	};

	return (
		<Card
			title="Certificado"
			extra={
				<Button onClick={() => setDisabled((state) => !state)}>
					{disabled ? "Editar" : "Cancelar"}
				</Button>
			}
		>
			{loading ? <Loading /> : null}

			{!loading && error ? <Error refetch={refetch} /> : null}

			{state && !loading && !error ? (
				<Form layout="vertical">
					<Row gutter={20}>
						<Col span={8}>
							<div
								style={{
									display: "flex",
									gap: "10px",
									alignItems: "flex-end",
								}}
							>
								<Form.Item name="file" label="Certificado">
									<Upload
										name={"file"}
										maxCount={1}
										beforeUpload={() => false}
										onChange={(e) => {
											setState((draft) => {
												draft.certificate = e.file;
											});
										}}
										accept="application/x-pkcs12"
									>
										<Button icon={<UploadOutlined />}>Enviar</Button>
									</Upload>
								</Form.Item>
								{state.certificate_url && (
									<Button
										onClick={() => {
											window.open(state.certificate_url);
										}}
										type="primary"
										style={{
											marginBottom: 24,
										}}
									>
										Baixar certificado
									</Button>
								)}
							</div>
						</Col>

						<Col span={8}>
							<Form.Item label="Senha Certificado">
								<Input.Password
									defaultValue={state?.certificate_password || ""}
									disabled={disabled}
									onChange={(e) => {
										setState((draft) => {
											draft.certificate_password = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Data Validade Certificado">
								<DatePicker
									value={state?.certificate_expiration_date || null}
									disabled={disabled}
									format={"DD/MM/YYYY"}
									onChange={(e) => {
										setState((draft) => {
											draft.certificate_expiration_date = e;
										});
									}}
									style={{ width: "100%" }}
								/>
							</Form.Item>
						</Col>
					</Row>
					{!disabled ? (
						<Col span={24}>
							<Button
								type="primary"
								style={{ float: "right" }}
								onClick={save}
								loading={loadingSave}
							>
								Salvar
							</Button>
						</Col>
					) : null}
				</Form>
			) : null}
		</Card>
	);
}

export default User;
