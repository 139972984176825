import { Select, Spin } from "antd";
import { useLazyQuery } from "@apollo/client";
import debounce from "functions/debounce";
import { gql } from "@apollo/client";
import { useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";

const UserSearch = ({
	onChange,
	value,
	id,
	disabled,
	additionalProps
}) => {
	const [request, businesses] = useLazyQuery(
		gql`
		query UserBusinesses($uid: String!)	 {
			UserBusinesses(uid: $uid) {
				_id
				company_name
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	console.log(value);

	useEffect(() => {
		if (id) {
			request({
				variables: {
					uid: id,
				},
			});
		}
	}, [id]);

	const el = useRef(null);

	return (
		<Select
			ref={el}
			disabled={disabled}
			onChange={(value, option) => {
				onChange(value, option.data);
			}}
			placeholder="Selecione"
			value={value}
			{...additionalProps}
		>
			{businesses?.data?.UserBusinesses?.length &&
				businesses.data.UserBusinesses.map((biz) => {
					return (
						<Select.Option key={biz._id} value={biz._id}>
							{biz.company_name}
						</Select.Option>
					);
				})}
		</Select>
	);
};

export default UserSearch;
