import { useState, useEffect, useReducer, useRef } from "react";
import * as Queries from "./Queries";
import { useLazyQuery, useMutation, gql } from "@apollo/client";

import Loading from "components/Loading/Page";
import Error from "components/Error/Page";

import Mask from "functions/mask";
import PartnerSearch from "components/PartnerSearch";

import {
	EditOutlined,
	MailOutlined, LockOutlined,
	MessageOutlined
} from "@ant-design/icons";

import MaskedInput from "antd-mask-input";
import {
	Layout,
	Table,
	Badge,
	Typography,
	Button,
	Select,
	Form,
	Input,
	Popconfirm,
	notification,
	Drawer,
	Tooltip, Divider,
	DatePicker
} from "antd";
import { statuses } from "./Misc";
import Import from "./components/Import";
import moment from "moment";

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

function Filters({
	open,
	onClose,
	filters,
	setFilters,
	filter,
	hasFilters,
	clearFilters,
}) {
	console.log(filters);

	return (
		<Drawer
			title="Filtros"
			placement="right"
			onClose={onClose}
			visible={open}
			width={600}
		>
			<Form layout="vertical">
				<Form.Item label="Nome">
					<Input
						value={filters?.name}
						onChange={(e) => {
							const { value } = e.target;
							setFilters((state) => {
								return {
									...state,
									name: value,
								};
							});
						}}
					/>
				</Form.Item>
				<Form.Item label="CPF">
					<MaskedInput
						mask={"111.111.111-11"}
						value={filters?.cpf}
						onChange={(e) => {
							const { value } = e.target;
							setFilters((state) => {
								return {
									...state,
									cpf: value,
								};
							});
						}}
					/>
				</Form.Item>
				<Form.Item label="CNPJ">
					<MaskedInput
						mask={"11.111.111/1111-11"}
						value={filters?.cnpj}
						onChange={(e) => {
							const { value } = e.target;
							setFilters((state) => {
								return {
									...state,
									cnpj: value,
								};
							});
						}}
					/>
				</Form.Item>
				<Form.Item label="E-mail">
					<Input
						value={filters?.email}
						onChange={(e) => {
							const { value } = e.target;
							setFilters((state) => {
								return {
									...state,
									email: value,
								};
							});
						}}
					/>
				</Form.Item>
				<Form.Item label="Data de Cadastro">
					<DatePicker.RangePicker
						style={{
							width: "100%",
						}}
						value={
							filters?.created_at?.length
								? [
										moment(filters.created_at[0]),
										moment(filters.created_at[1]),
								  ]
								: null
						}
						format={"DD/MM/YYYY"}
						onChange={(e) => {
							if (!e) {
								setFilters((state) => {
									return {
										...state,
										created_at: [],
									};
								});
							} else {
								setFilters((state) => {
									return {
										...state,
										created_at: [e[0].toISOString(), e[1].toISOString()],
									};
								});
							}
						}}
					/>
				</Form.Item>
				<Form.Item label="Status">
					<Select
						value={filters?.status}
						onChange={(e) => {
							setFilters((state) => {
								return {
									...state,
									status: e,
								};
							});
						}}
					>
						<Select.Option value="">Qualquer</Select.Option>
						<Select.Option value="0">Expirado</Select.Option>
						<Select.Option value="1">Ativo</Select.Option>
						<Select.Option value="2">
							Não cadastrou/requisitou empresa
						</Select.Option>
						<Select.Option value="3">Aguardando Abertura</Select.Option>
						<Select.Option value="4">Inativo</Select.Option>
						<Select.Option value="5">Cancelado</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item label="Parceiro">
					<PartnerSearch
						onChange={(e) => {
							setFilters((state) => {
								return {
									...state,
									partner_id: e,
								};
							});
						}}
						additionalProps={{
							allowClear: true,
						}}
					/>
				</Form.Item>

				<Divider />

				<div className="action-handler">
					<Button onClick={filter} type="primary">
						Filtrar
					</Button>

					{hasFilters() ? (
						<Button onClick={clearFilters} style={{ marginLeft: "10px" }}>
							Limpar
						</Button>
					) : null}
				</div>
			</Form>
		</Drawer>
	);
}

function Users(props) {
	const mounted = useRef(false);
	const [resendId, setResendId] = useState(null);
	const [welcomeEmail, setWelcomeEmail] = useState(null);
	const [, forceUpdate] = useReducer((x) => x + 1, 0);

	const [loadingPagination, setLoadingPagination] = useState(false);
	const [selected, setSelected] = useState(false);

	const [resetUserPass, { loading: loadingUserPass, data: dataUserPass }] =
		useMutation(Queries.USER_RESET_PASSWORD);
	const [
		welcomeEmailSend,
		{ loading: loadingWelcomeEmail, data: welcomeEmailData },
	] = useMutation(Queries.USER_WELCOME_EMAIL);

	const [exportAction, exporting] = useMutation(
		gql`
		mutation UsersExport($ids: [String])	 {
			UsersExport(ids: $ids)
		}
	`,
		{
			fetchPolicy: "no-cache",
			onCompleted: (data) => {
				console.log(data);
				if (data.UsersExport) {
					window.open(data.UsersExport);
				}
			},
			onError: () => {
				notification.error({
					message: "Não foi possível exportar os usuários no momento.",
				});
			},
		}
	);

	const [load, { loading, data, error, refetch, called }] = useLazyQuery(
		Queries.USERS,
		{
			fetchPolicy: "no-cache",
		}
	);

	const [filters, setFilters] = useState({
		status: "1",
	});

	const [filtersOpen, setFiltersOpen] = useState(false);

	const [pages, setPages] = useState({
		current: 1,
	});

	useEffect(() => {
		filter();
		mounted.current = true;
	}, []);

	useEffect(() => {
		if (dataUserPass) {
			if (dataUserPass?.UserResetPassword) {
				setResendId(null);
				notification.success({
					message: "E-mail de redefinição de senha enviado com sucesso!",
				});
			} else {
				notification.error({
					message: "Erro",
					description: "Não foi possível redefinir a senha no momento.",
				});
			}
		}
		if (welcomeEmailData) {
			if (welcomeEmailData?.UserResendWelcomeEmail) {
				setWelcomeEmail(false);
				notification.success({
					message: "E-mail enviado com sucesso!",
				});
			} else {
				notification.error({
					message: "Erro",
					description: "Não foi possível enviar o e-mail no momento.",
				});
			}
		}	
	}, [dataUserPass, welcomeEmailData]);

	const filter = () => {
		setPages({ current: 1 });
		load({
			variables: {
				filters,
				page: 1,
				perPage: 10,
			},
		});
	};

	const hasFilters = () => {
		let has = false;
		for (var prop in filters) {
			if (filters[prop] || filters[prop] !== "") has = true;
		}
		return has;
	};

	const clearFilters = () => {
		setPages({ current: 1 });
		setFilters({
			status: "1",
		});
		forceUpdate();
		load({
			variables: {
				page: 1,
			},
		});
	};

	const getFilterValue = (filters, path) => {
		return filters[path] || null;
	};

	const columns = [
		{
			title: "Nome",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			render: (email) => email || "-",
		},
		{
			title: "Fone",
			dataIndex: "phone",
			key: "phone",
			render: (phone) => {
				return phone ? Mask(phone, "(99) 99999-9999") : "-";
			},
		},
		{
			title: "CNPJ",
			dataIndex: "cnpj",
			key: "cnpj",
			render: (cnpj) => cnpj || "-",
		},
		{
			title: "Empresa",
			dataIndex: "company",
			key: "company",
			render: (value, row) => row?.partner_company?.name || "-",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			width: 120,
			render: (status) => {
				return statuses[status];
			},
		},
		{
			title: "Acessos",
			dataIndex: "accesses",
			key: "accesses",
			width: 100,
			render: (status, row) => {
				return (
					<div>
						<Tooltip title="gov.br">
							<Badge status={row?.gov_br_password ? "success" : "warning"} />
						</Tooltip>
						<Tooltip title="Emissor Nacional">
							<Badge status={row?.external_password ? "success" : "warning"} />
						</Tooltip>
						<Tooltip title="Certificado">
							<Badge
								status={row?.certificate_password ? "success" : "warning"}
							/>
						</Tooltip>
					</div>
				);
			},
		},
		{
			title: "Ações",
			dataIndex: "",
			key: "x",
			width: 200,
			render: (value, row) => {
				return (
					<div>
						<Tooltip title="Editar">
							<Button
								shape="circle"
								icon={<EditOutlined />}
								style={{ marginRight: 8 }}
								onClick={() => {
									props.history.push(`/user/${row._id}`);
								}}
							/>
						</Tooltip>

						<Tooltip title="Atendimento">
							<Button
								shape="circle"
								icon={<MessageOutlined />}
								style={{ marginRight: 8 }}
								onClick={() => {
									props.history.push(`/user/logs/${row._id}`);
								}}
							/>
						</Tooltip>

						<Tooltip title="Reenviar E-mail">
							<Popconfirm
								title="Reenviar e-mail de boas-vindas?"
								okText="Sim"
								cancelText="Não"
								visible={welcomeEmail === row._id ? true : false}
								onCancel={() => setWelcomeEmail(null)}
								okButtonProps={{ loading: loadingWelcomeEmail }}
								onConfirm={() => {
									welcomeEmailSend({
										variables: {
											id: row._id,
										},
									});
								}}
							>
								<Button
									shape="circle"
									icon={<MailOutlined />}
									style={{ marginRight: 8 }}
									onClick={() => setWelcomeEmail(row._id)}
								/>
							</Popconfirm>
						</Tooltip>

						<Tooltip title="Redefinir Senha">
							<Popconfirm
								title="Deseja redefinir a senha?"
								okText="Sim"
								cancelText="Não"
								visible={resendId === row._id ? true : false}
								onCancel={() => setResendId(null)}
								okButtonProps={{ loading: loadingUserPass }}
								onConfirm={() => {
									resetUserPass({
										variables: {
											id: row._id,
										},
									});
								}}
							>
								<Button
									shape="circle"
									icon={<LockOutlined />}
									onClick={() => setResendId(row._id)}
								/>
							</Popconfirm>
						</Tooltip>
					</div>
				);
			},
		},
	];

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>Usuários</Title>

					<div style={{ display: "flex" }}>
						<Button
							style={{ marginRight: 10 }}
							onClick={async () => {
								setFiltersOpen(true);
							}}
						>
							Filtros
						</Button>
						{selected.length ? (
							<Button
								style={{ marginRight: 10 }}
								loading={exporting.loading}
								onClick={async () => {
									await exportAction({
										variables: {
											ids: selected,
										},
									});
								}}
							>
								Exportar
							</Button>
						) : null}
						<Import />
						<Button
							type="primary"
							style={{ marginLeft: 10 }}
							onClick={() => {
								props.history.push("/create-user");
							}}
						>
							Novo Usuário
						</Button>
					</div>
				</div>

				<div className="dashboard">
					{loading ? <Loading /> : null}

					{!loading && !error && data ? (
						<div>
							<Table
								rowKey="_id"
								dataSource={data.Users.users.length ? data.Users.users : []}
								columns={columns}
								loading={loadingPagination}
								pagination={{
									defaultCurrent: data.Users.currentPage,
									total: data.Users.total,
									showSizeChanger: true,
									defaultPageSize: 10,
									pageSizeOptions: [10, 20, 50, 100, 500, 1000],
								}}
								rowSelection={{
									type: "checkbox",
									onChange: (selectedRowKeys, selectedRows) => {
										setSelected(selectedRowKeys);
									},
								}}
								onChange={async (pagination) => {
									console.log(pagination);

									setLoadingPagination(true);
									await refetch({
										page: pagination.current,
										filters,
										perPage: pagination.pageSize,
									});
									setLoadingPagination(false);
								}}
							/>
						</div>
					) : null}

					{!loading && error ? <Error refetch={() => refetch()} /> : null}
				</div>

				<Filters
					open={filtersOpen}
					onClose={() => {
						setFiltersOpen(false);
					}}
					filter={filter}
					hasFilters={hasFilters}
					clearFilters={clearFilters}
					setFilters={setFilters}
					filters={filters}
				/>
			</Content>
		</Layout>
	);
}

export default Users;
