import React, { useState, useEffect, useRef } from "react";
import * as Queries from "../Queries";
import { useQuery, useMutation } from "@apollo/client";
import Swal from "sweetalert2";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import _ from "lodash";
import MaskedInput from "antd-mask-input";
import isEmail from "validator/lib/isEmail";

import moment from "moment";

import {
	Col,
	Row,
	Layout,
	Table,
	Typography,
	Button,
	Card,
	Form,
	Input,
	Select,
	notification,
	DatePicker,
	Alert,
	Checkbox,
	Spin,
} from "antd";

import { gql } from "@apollo/client";
import { useImmer } from "use-immer";

const { Content } = Layout;
const { Title } = Typography;


function User({ id, business_id, refetchParent }) {
	const [disabled, setDisabled] = useState(true);
	const [state, setState] = useState(null);
	const [ctnSearch, setCtnSearch] = useState(null);

	const [saveAction, { loading: loadingSave }] = useMutation(
		Queries.BUSINESS_SAVE
	);



	const { loading, data, error } = useQuery(Queries.BUSINESS, {
		fetchPolicy: "no-cache",
		variables: {
			uid: id,
			business_id: business_id
		},
	});

	useEffect(() => {
		if (data) setState(data.Business || {});
	}, [data]);

	const getValue = (path) => {
		return _.get(state, path);
	};

	const setValue = (path, value) => {
		setState((state) => {
			_.set(state, path, value);
			return state;
		});
	};

	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			type: "error",
			confirmButtonText: "OK",
		});
	};

	const save = async () => {
		if (!state.company_name)
			return ErrorDialog("Por favor, insira uma Razão Social válida");

		let obj = {
			...state,
		};

		const { data, error } = await saveAction({
			variables: {
				input: {
					...obj
				},
				uid: id,
				business_id
			},
		});

		if (error) {
			ErrorDialog(
				"Não foi possível salvar as informações no momento. Tente novamente mais tarde."
			);
		} else {
			notification.success({
				message: "Sucesso",
				description: "Informações salvas com sucesso",
			});
			//refetch();
			refetchParent();
		}
	};

	const empty = (obj) => {
		for (var prop in obj) {
			if (obj.hasOwnProperty(prop)) {
				return false;
			}
		}
		return JSON.stringify(obj) === JSON.stringify({});
	};

	return (
		<Card
			title="Empresa"
			extra={
				<Button onClick={() => setDisabled((state) => !state)}>
					{disabled ? "Editar" : "Cancelar"}
				</Button>
			}
		>
			{loading ? <Loading /> : null}

			{!loading && error ? <Error /> : null}

			{state && !loading && !error ? (
				<div>
					{empty(state) ? (
						<div style={{ marginBottom: "20px" }}>
							<Alert
								message="Empresa não encontrada. Favor preencher os dados."
								type="error"
							/>
						</div>
					) : null}

					<Form layout="vertical">
						<Row gutter={20}>
							<Col span={16}>
								<Form.Item name="razao" label="Razão Social">
									<Input
										disabled={disabled}
										defaultValue={getValue("company_name")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("company_name", value);
										}}
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item name="cnpj" label="CNPJ">
									<MaskedInput
										mask={"11.111.111/1111-11"}
										disabled={disabled}
										defaultValue={getValue("cnpj")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("cnpj", value);
										}}
									/>
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item name="trade_name" label="Nome Fantasia">
									<Input
										disabled={disabled}
										defaultValue={getValue("trade_name")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("trade_name", value);
										}}
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item name="state_registration" label="Inscrição Estadual">
									<Input
										disabled={disabled}
										defaultValue={getValue("state_registration")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("state_registration", value);
										}}
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item name="email" label="E-mail da Empresa">
									<Input
										disabled={disabled}
										defaultValue={getValue("email")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("email", value);
										}}
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item name="access_code" label="Código de Acesso Nacional">
									<Input
										disabled={disabled}
										defaultValue={getValue("access_code")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("access_code", value);
										}}
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									name="municipal_registration"
									label="Registro Municipal"
								>
									<Input
										disabled={disabled}
										defaultValue={getValue("municipal_registration")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("municipal_registration", value);
										}}
									/>
								</Form.Item>
							</Col>

							<Col span={8}>
								<Form.Item
									name="real_estate_registration"
									label="Inscrição Imobiliária"
								>
									<Input
										disabled={disabled}
										defaultValue={getValue("real_estate_registration")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("real_estate_registration", value);
										}}
									/>
								</Form.Item>
							</Col>
						
						

							{/* <CNAEList id={id} />

							<CTNList
								id={id}
								value={state?.ctn}
								disabled={disabled}
								onChange={(values) => {
									setValue("ctn", values);
								}}
							/> */}

							{!disabled ? (
								<Col span={24} style={{ marginTop: 20 }}>
									<Button
										type="primary"
										style={{ float: "right" }}
										onClick={save}
										loading={loadingSave}
									>
										Salvar
									</Button>
								</Col>
							) : null}
						</Row>
					</Form>
				</div>
			) : null}
		</Card>
	);
}

export default User;
