import React from "react";
import { Route, Switch } from "react-router-dom";
// Scenes Imports

import Dashboard from "./dashboard";
import Services from "./services";
import Tickets from "./tickets";
import * as Users from "./users";
import * as MEI from "./mei";
import Admins from "./admins";
import * as Companies from "./companies";
import * as Partners from "./partners";
import UAU from "./uau";
import ActiveDebt from "./activeDebts";
import Tutorials from "./tutorials";
import Prospects from "./prospects";
import GlobalPlans from "./globalPlans";
import Checkouts from "./checkouts";

import NotFound from "./not-found";

// Routes Config

const routes = [
	{
		path: "/",
		exact: true,
		component: Dashboard,
	},
	{
		path: "/services",
		exact: true,
		component: Services,
	},
	{
		path: "/users",
		exact: true,
		component: Users.List,
	},
	{
		path: "/abertura-mei",
		exact: true,
		component: MEI.List,
	},
	{
		path: "/user/:id",
		exact: true,
		component: Users.User,
	},
	{
		path: "/user/logs/:id",
		exact: true,
		component: Users.Logs,
	},
	{
		path: "/create-user",
		exact: true,
		component: Users.New,
	},
	{
		path: "/tickets",
		exact: true,
		component: Tickets,
	},
	{
		path: "/admins",
		exact: true,
		component: Admins,
	},
	{
		path: "/companies",
		exact: true,
		component: Companies.List,
	},
	{
		path: "/company/:id",
		exact: true,
		component: Companies.View,
	},
	{
		path: "/partners",
		exact: true,
		component: Partners.List,
	},
	{
		path: "/partners/groups",
		exact: true,
		component: Partners.Groups,
	},
	{
		path: "/partner/:id",
		exact: true,
		component: Partners.View,
	},
	{
		path: "/partner/:id",
		exact: true,
		component: Partners.View,
	},
	{
		path: "/uau",
		exact: true,
		component: UAU,
	},
	{
		path: "/checkouts",
		exact: true,
		component: Checkouts,
	},
	{
		path: "/tutorials",
		exact: true,
		component: Tutorials,
	},
	{
		path: "/prospects",
		exact: true,
		component: Prospects,
	},
	{
		path: "/global-plans",
		exact: true,
		component: GlobalPlans,
	},
	{
		path: "/active-debts",
		exact: true,
		component: ActiveDebt,
	},
];

// if (process.env.REACT_APP_CHECKOUTS) {
// 	routes.push({
// 		path: "/checkouts",
// 		exact: true,
// 		component: Checkouts,
// 	});
// }

// Routes Functions

function RouteWithSubRoutes(route) {
	return (
		<Route
			path={route.path}
			exact={route.exact ? true : false}
			render={(props) => <route.component {...props} routes={route.routes} />}
		/>
	);
}

function RouteConfig() {
	let returnArray = [];
	routes.map((route, i) => {
		let routeObj = {
			...route,
		};
		returnArray.push(<RouteWithSubRoutes key={i} {...routeObj} />);
	});

	//return returnArray;

	return (
		<Switch>
			{returnArray}
			<Route component={NotFound} />
		</Switch>
	);
}

export default RouteConfig;
