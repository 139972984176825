import { gql } from "@apollo/client";

export const USERS = gql`
  query Users($filters: UserListFilters, $page: Int!, $perPage: Int) {
    Users(filters: $filters, page: $page, perPage: $perPage) {
      users {
        _id
        email
        name
        cpf
        cnpj
        phone
        status
        plan
        partner_company {
          name
        }
        certificate_password  
      }
      pages
      currentPage
      total
    }
  }
`;

export const USER = gql`
query User($id: String!) {
  User(uid: $id) {
    email
    name
    cpf
    phone
    status
    plan
    iugu_customer_id
    iugu_subscription_id
    plan
    notes
    partner_id
    partner_plan
    partner_name
    certificate_password
    certificate_url
  }
}
`;

export const USER_SAVE = gql`
mutation User($input:UserInput!) {
  User(input:$input) {
    _id
    email
    name
    cpf
    phone
    status
    plan
    updatedAt
    createdAt
    iugu_customer_id
    iugu_subscription_id
    plan
    partner_id
    partner_plan
    partner_name
    certificate_password
    certificate_url
  }
}
`;

export const BUSINESS = gql`
  query Business($uid: String!, $business_id: String!) {
    Business(uid: $uid, business_id: $business_id) {
      cnpj
      company_name
      trade_name
      email
      access_code
      municipal_registration
      state_registration
      inactivation_date
      ctn
    }
  }
`;

export const BUSINESS_SAVE = gql`
mutation Business($input: BusinessInput!, $uid: String!, $business_id: String!) {
  Business(uid: $uid, business_id: $business_id, input: $input) {
    _id
  }
}
`;

export const ADDRESS = gql`
query AddressByUser($id: String!, $business_id: String!) {
  AddressByUser(id: $id, business_id: $business_id) {
    addressable_type
    street
    zip_code
    number
    complement
    neighborhood
    city
    state
    municipal_code
  }
}
`;

export const ADDRESS_SAVE = gql`
mutation AddressByUser($input: AddressInput!, $id: String!, $business_id: String!) {
  AddressByUser(input: $input, id:$id, business_id: $business_id) {
    _id
  }
}
`;

export const CONTRACT = gql`
query Contract($uid: String!, $business_id: String!) {
  Contract(uid: $uid, business_id: $business_id) {
    responsibility_start
    signature_date
  }
}
`;

export const CONTRACT_SAVE = gql`
mutation Contract($uid: String!, $input: ContractInput!) {
  Contract(uid: $uid, input: $input) {
    responsibility_start
    signature_date
  }
}
`;

export const DOCUMENTS = gql`
query Business($id: String!, $business_id: String!) {
  Business(uid: $id, business_id:$business_id) {
    documents {
        alvara {
            name
            path
        }
        cartao_cnpj {
            name
            path
        }
        certificado_mei {
            name
            path
        }
        rg {
            name
            path
        }
        cpf {
            name
            path
        }
        cnh {
            name
            path
        }
        titulo_eleitor {
            name
            path
        }
        iptu {
            name
            path
        }
    }
  }
}
`;

export const DOCUMENT_UPLOAD = gql`
mutation BusinessDocument($input: BusinessDocumentInput!, $uid: String!, $business_id: String!) {
  BusinessDocument(input: $input, uid: $uid, business_id:$business_id) {
      name
      path
      type
      key
  }
}
`;

export const CREATE_USER = gql`
mutation CreateUser($input:CreateUserInput!) {
  CreateUser(input:$input) {
    result
    error
  }
}
`;

export const USER_DOCUMENTS = gql`
query UserDocuments($id: String!) {
  UserDocuments(uid: $id) {
    mother_name
    rg
    birth_date
    voter_title
    tax_code
  }
}
`;

export const USER_DOCUMENTS_SAVE = gql`
mutation UserDocuments($input: CreateUserDocuments!, $uid: String!) {
  UserDocuments(input: $input, uid: $uid) {
    mother_name
    rg
    birth_date
    voter_title
    tax_code
  }
}
`;

export const USER_RESET_PASSWORD = gql`
mutation UserResetPassword($id: String!) {
  UserResetPassword(id: $id)
}
`;

export const USER_WELCOME_EMAIL = gql`
mutation UserResendWelcomeEmail($id: String!) {
  UserResendWelcomeEmail(id: $id)
}
`;

export const LOGS_SAVE = gql`
mutation UsersLog($input: UserLogInput!) {
  UsersLog(input: $input)
}
`;

export const LOGS_GET = gql`
query UsersLogs($id: String!) {
  UsersLogs(id: $id) {
    content
    createdAt
    userName
    author
  }
}
`;

export const USER_IMPORT = gql`
  mutation UserImport($file: Upload!, $partner: String) {
    UserImport(file: $file, partner: $partner) {
      result
      error
      notCreated
    }
  }
`;

export const USER_REMOVE = gql`
  mutation UserRemove($id: String!) {
    UserRemove(id: $id)
  }
`;

export const ADD_BUSINESS = gql`
mutation AddBusiness($input:CreateUserInput!, $uid: String!) {
  AddBusiness(input:$input, uid:$uid) {
    result
    error
  }
}
`;
