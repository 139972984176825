import React, { useEffect, useState, useRef } from "react";
import * as Queries from "./Queries";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import dayjs from "dayjs";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import MaskPrice from "functions/mask/price";
import UserSearch from "components/UserSearch";

import {
	Col,
	Row,
	Layout,
	Table,
	Badge,
	Typography,
	Button,
	Select,
	Form,
	notification,
	Radio,
	Tooltip,
	Popconfirm,
	Drawer,
} from "antd";

import Observations from "./Observations";
import AdditionalData from "./AdditionalData";
import TicketConversation from "./TicketConversation";

import {
	LockOutlined,
	DashOutlined,
	AlignLeftOutlined,
	DeleteOutlined,
	MessageOutlined,
} from "@ant-design/icons";

import { statuses } from "./Misc";
import { useImmer } from "use-immer";

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

function Tickets() {
	const mounted = useRef(false);

	//
	// Queries
	//

	const [loadingPagination, setLoadingPagination] = useState(false);

	const [filters, setFilters] = useState({
		all: false,
		status: "",
	});

	const [load, { loading, data, error, refetch }] = useLazyQuery(
		Queries.TICKETS,
		{
			fetchPolicy: "no-cache",
			variables: {
				page: 1,
			},
		}
	);

	const {
		loading: adminsLoading,
		data: adminsData,
		error: adminsError,
		refetch: adminsRefetch,
	} = useQuery(Queries.ADMINS, {
		fetchPolicy: "no-cache",
		variables: {
			status: 1
		}
	});

	const [modal, setModal] = useState({
		visible: false,
		ticketId: null,
		observation: null,
	});

	const [conversation, setConversation] = useImmer({
		visible: false,
	});

	const [modalAdditional, setModalAdditional] = useState({
		visible: false,
		ticketId: null,
		observation: null,
	});

	const [saveTicket, { loading: loadingSave }] = useMutation(
		Queries.TICKET_SAVE
	);

	const [removeTicketAction, { loading: loadingRemove }] = useMutation(
		Queries.TICKET_REMOVE
	);

	//
	// Filters
	//

	const getFilterValue = (filters, path) => {
		return filters[path] || null;
	};

	const loadResults = () => {
		load({
			variables: {
				input: { ...filters },
				page: 1,
			},
		});
	};

	useEffect(() => {
		if (mounted.current) {
			loadResults();
		}
	}, [filters]);

	useEffect(() => {
		mounted.current = true;
		loadResults();
	}, []);

	//
	// Functions
	//

	const save = async (data) => {
		const { data: res, error } = await saveTicket({
			variables: data,
		});

		if (!error) {
			notification.success({
				message: "Ticket salvo com sucesso.",
			});
			refetch();
			if (window.refetchBadges) {
				window.refetchBadges();
			}
		} else {
			notification.error({
				message: "Erro",
				description: "Não foi possível salvar o ticket no momento.",
			});
		}
	};

	const removeTicket = async (row) => {
		let response = await removeTicketAction({
			variables: {
				id: row._id,
			},
		});

		if (response?.data?.TicketRemove) {
			notification.success({
				message: "Ticket removido com sucesso.",
			});
			refetch();
		} else {
			notification.error({
				message: "Erro",
				description: "Não foi possível remover o ticket no momento.",
			});
		}
	};

	//
	// Misc
	//

	const badgeColors = {
		0: "#f2423a",
		1: "#1494fc",
		2: "#fe961e",
		3: "#50ad54",
		4: "#9e9e9e",
	};

	//
	// Table Columns
	//

	const columns = [
		{
			title: "Serviço",
			dataIndex: "service",
			key: "service",
			render: (text, rec) => {
				return (
					<div>
						<Badge color={badgeColors[rec.status]} />
						{text.name}
					</div>
				);
			},
		},
		{
			title: "Aberto em",
			dataIndex: "createdAt",
			key: "createdAt",
			render: (text) => {
				return dayjs(text).format("DD/MM/YYYY - HH:mm");
			},
		},
		{
			title: "Resolução",
			dataIndex: "resolution_date",
			key: "resolution_date",
			render: (text) => {
				return text ? dayjs(text).format("DD/MM/YYYY - HH:mm") : "--";
			},
		},
		{
			title: "Parceiro",
			dataIndex: "partner",
			key: "partner",
			render: (value, row) => {
				if (row?.partner?._id) {
					return (
						<a href={`./partner/${row.partner._id}`}>
							{row.partner.company_name}
						</a>
					);
				}
				return "--";
			},
		},
		{
			title: "Usuário",
			dataIndex: "user",
			key: "user",
			render: (value, row) => {
				if (row?._id && row?.user?.name) {
					return <a href={`./user/${row.user._id}`}>{row.user.name}</a>;
				}
				return "--";
			},
		},
		{
			title: "Empresa",
			dataIndex: "business_name",
			key: "business_name",
			render: (value, row) => {
				return value ?? "-"
			},
		},
		{
			title: "CPF",
			dataIndex: "user",
			key: "cpf",
			render: (row) => {
				return row?.cpf || "--";
			},
		},
		{
			title: "Responsável",
			dataIndex: "admin_id",
			key: "admin_id",
			render: (row, rec) => {
				return (
					<Select
						defaultValue={row}
						style={{ width: 200 }}
						onChange={(id) => {
							save({
								admin: id,
								id: rec._id,
							});
						}}
					>
						{adminsData.Admins.map((item, index) => {
							return (
								<Option key={`admin_id_${index}`} value={item._id}>
									{item.name}
								</Option>
							);
						})}
					</Select>
				);
			},
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (row, rec) => {
				return (
					<Select
						defaultValue={row.toString()}
						style={{ width: 200 }}
						onChange={(id) => {
							save({
								status: id,
								id: rec._id,
							});
						}}
					>
						<Option value="0">Aguardando</Option>
						<Option value="1">Em andamento</Option>
						<Option value="2">Aguardando cliente</Option>
						<Option value="3">Resolvido</Option>
						<Option value="4">Cancelado</Option>
					</Select>
				);
			},
		},
		{
			title: "Ações",
			dataIndex: "",
			key: "x",
			width: 200,
			render: (value, row) => {
				return (
					<div>
						<Tooltip title="Mensagens">
							{row?.unread ? (
								<Badge dot>
									<Button
										shape="circle"
										icon={<MessageOutlined />}
										onClick={() => {
											setConversation({
												visible: true,
												data: row,
											});
										}}
									/>
								</Badge>
							) : (
								<Button
									shape="circle"
									icon={<MessageOutlined />}
									onClick={() => {
										setConversation({
											visible: true,
											data: row,
										});
									}}
								/>
							)}
						</Tooltip>

						<Tooltip title="Informações">
							<Button
								shape="circle"
								style={{ marginLeft: 10 }}
								icon={<DashOutlined />}
								onClick={() => {
									setModalAdditional({
										visible: true,
										ticketId: row._id,
									});
								}}
							/>
						</Tooltip>

						<Tooltip title="Observações">
							<Button
								style={{ marginLeft: 10 }}
								shape="circle"
								icon={<AlignLeftOutlined />}
								onClick={() => {
									setModal({
										visible: true,
										ticketId: row._id,
										observation: row.observation,
									});
								}}
							/>
						</Tooltip>

						<Tooltip title="Remover">
							<Popconfirm
								title="Deseja remover este ticket?"
								onConfirm={() => {
									removeTicket(row);
								}}
								onCancel={null}
								okText="Sim"
								cancelText="Não"
							>
								<Button
									style={{ marginLeft: 10 }}
									shape="circle"
									icon={<DeleteOutlined />}
								/>
							</Popconfirm>
						</Tooltip>
					</div>
				);
			},
		},
	];

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>Tickets</Title>
				</div>

				<div style={{ marginBottom: "30px" }}>
					<Row gutter={20}>
						<Col>
							<Radio.Group
								defaultValue="0"
								buttonStyle="solid"
								style={{ marginBottom: "20px" }}
								onChange={(e) => {
									setFilters((state) => {
										return {
											...state,
											all: e.target.value === "1" ? true : false,
										};
									});
								}}
							>
								<Radio.Button value="0">Meus</Radio.Button>
								<Radio.Button value="1">Todos</Radio.Button>
							</Radio.Group>
						</Col>
						<Col span={4}>
							<Form.Item>
								<Select
									value={getFilterValue(filters, "status")}
									onChange={(e) => {
										setFilters((state) => {
											return {
												...state,
												status: e,
											};
										});
									}}
									placeholder={"Status"}
								>
									<Option value="all">Todos</Option>
									<Option value="0">Aguardando</Option>
									<Option value="1">Em andamento</Option>
									<Option value="2">Aguardando cliente</Option>
									<Option value="3">Resolvido</Option>
									<Option value="4">Cancelado</Option>
								</Select>
							</Form.Item>
						</Col>
						{filters.all ? (
							<Col span={4}>
								<Form.Item>
									<Select
										value={getFilterValue(filters, "admin_id")}
										loading={adminsLoading}
										onChange={(e) => {
											setFilters((state) => {
												return {
													...state,
													admin_id: e,
												};
											});
										}}
										placeholder={"Responsável"}
									>
										<Option key={`admin_id_all`} value={"all"}>
											Todos
										</Option>
										{adminsData &&
											adminsData.Admins.map((item, index) => {
												return (
													<Option key={`admin_id_${index}`} value={item._id}>
														{item.name}
													</Option>
												);
											})}
									</Select>
								</Form.Item>
							</Col>
						) : null}
						<Col flex="auto">
							<Form.Item>
								<UserSearch
									onChange={(e) => {
										setFilters((state) => {
											return {
												...state,
												user_id: e,
											};
										});
									}}
									additionalProps={{
										allowClear: true,
									}}
									additionalVariables={{
										status: 1
									}}
								/>
							</Form.Item>
						</Col>
					</Row>
				</div>

				<div className="dashboard">
					{loading || adminsLoading ? <Loading /> : null}

					{!loading &&
					!adminsLoading &&
					!error &&
					!adminsError &&
					adminsData &&
					data ? (
						<div>
							<Table
								rowKey="_id"
								dataSource={
									data?.Tickets?.tickets?.length ? data.Tickets.tickets : []
								}
								columns={columns}
								loading={loadingPagination}
								pagination={{
									defaultCurrent: data.Tickets.currentPage,
									total: data.Tickets.total,
									showSizeChanger: false,
									defaultPageSize: 10,
								}}
								onChange={async (pagination) => {
									setLoadingPagination(true);
									await refetch({
										page: pagination.current,
										filters,
									});
									setLoadingPagination(false);
								}}
							/>
						</div>
					) : null}

					{!loading && (error || adminsError) ? (
						<Error refetch={() => refetch()} />
					) : null}
				</div>

				<Observations
					data={modal}
					visible={modal.visible}
					setModal={setModal}
					refetch={refetch}
				/>

				<AdditionalData
					data={modalAdditional}
					visible={modalAdditional.visible}
					setModal={setModalAdditional}
				/>

				<TicketConversation
					{...conversation}
					refetchTop={refetch}
					close={() => {
						setConversation({
							visible: false,
						});
					}}
				/>
			</Content>
		</Layout>
	);
}

export default Tickets;
