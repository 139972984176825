import { useEffect, useRef } from "react";
import Mask from "functions/mask";
import {
	Modal,
	Button, Descriptions,
	Form,
	Input,
	Select,
	Divider, Badge,
	DatePicker,
	Upload, Tooltip,
	Table,
	Spin
} from "antd";
import InputNumber from "components/InputNumber";
import { useMutation, useLazyQuery, gql } from "@apollo/client";
import Swal from "sweetalert2";
import { useImmer } from "use-immer";
import moment from "moment";
import { PlusOutlined, UploadOutlined, FileOutlined } from "@ant-design/icons";
import MaskPrice from "functions/mask/price";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

function InstallmentModal({
	visible,
	close,
	installment_id,
	data,
	refetch,
	currentEntries,
}) {
	const [state, setState] = useImmer({});
	const [list, setList] = useImmer([]);

	const [saveAction, { loading }] = useMutation(gql`
	mutation ActiveDebtEntry($input: ActiveDebtEntryInput, $installment_id: String!, $id: String) {
		ActiveDebtEntry(input: $input, installment_id: $installment_id, id: $id) {
			_id
		}
	}
`);

	useEffect(() => {
		if (visible) {
			if (data) {
				let { fileURL, _id, ...obj } = data;
				if (obj.paymentDate) obj.paymentDate = moment(obj.paymentDate);
				if (obj.dueDate) obj.dueDate = moment(obj.dueDate);
				setState(obj);
			} else {
				setState((d) => {
					d.status = "pending";
				});
			}

			if (currentEntries.length) {
				setState((d) => {
					d.installment_number =
						currentEntries[currentEntries.length - 1].installment_number + 1;
				});
			}
		}
	}, [visible, data]);

	const save = async () => {
		if (!state?.installment_number)
			return ErrorDialog("Por favor, informe o número da parcela.");

		if (!data && !state?.file) {
			return ErrorDialog("Por favor, selecione um arquivo.");
		}

		if (!state?.dueDate)
			return ErrorDialog("Por favor, selecione uma data de vencimento.");
		if (!state?.subtotal) return ErrorDialog("Por favor, informe o valor.");
		// if (!state?.fine)
		// 	return ErrorDialog("Por favor, informe o valor da multa.");
		// if (!state?.fees)
		// 	return ErrorDialog("Por favor, informe o valor dos juros.");
		if (!state?.total) return ErrorDialog("Por favor, informe o valor total.");
		if (!state?.status) return ErrorDialog("Por favor, informe um status.");

		let input = { ...state };
		input.dueDate = input.dueDate.toISOString();
		if (input.paymentDate) input.paymentDate = input.paymentDate.toISOString();

		input.installment_number = parseInt(input.installment_number);

		try {
			let res = await saveAction({
				variables: {
					input,
					installment_id,
					id: data?._id ? data._id : null,
				},
			});

			if (res?.data?.ActiveDebtEntry?._id) {
				refetch();
				close();
				setState({});
				setList([]);
			}

			console.log(res);
		} catch (error) {
			console.log(error);
		}
	};

	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			type: "error",
			confirmButtonText: "OK",
		});
	};

	const onClose = () => {
		close();
		setState({});
		setList([]);
	};

	useEffect(() => {
		if (
			state?.subtotal !== null ||
			state?.fine !== null ||
			state?.fees !== null
		) {
			let total = 0;

			if (state.subtotal && !isNaN(state.subtotal)) {
				total += state.subtotal;
			}
			if (state.fine && !isNaN(state.fine)) {
				total += state.fine;
			}
			if (state.fees && !isNaN(state.fees)) {
				total += state.fees;
			}

			if (total > 0) {
				setState((d) => {
					d.total = total;
				});
			} else {
				setState((d) => {
					d.total = 0;
				});
			}
		}
	}, [state]);

	return (
		<Modal
			title={data?._id ? "Editar Parcela" : "Adicionar Parcela"}
			visible={visible}
			onOk={save}
			onCancel={onClose}
			footer={[
				<Button key="back" onClick={onClose} disabled={loading}>
					Cancelar
				</Button>,
				<Button
					type="primary"
					loading={loading}
					disabled={loading}
					onClick={save}
				>
					Enviar
				</Button>,
			]}
		>
			<Form layout="vertical">
				<Form.Item label="Número da Parcela">
					<Input
						type="number"
						style={{ width: "100%" }}
						value={state?.installment_number ? state.installment_number : null}
						onChange={(e) => {
							setState((d) => {
								d.installment_number = e.target.value;
							});
						}}
					/>
				</Form.Item>
				<Form.Item label="Arquivo">
					<div style={{ display: "flex" }}>
						<Upload
							beforeUpload={() => false}
							onChange={(e) => {
								const { file } = e;
								setState((draft) => {
									draft.file = file;
								});
								setList([file]);
							}}
							fileList={list}
						>
							<Button icon={<UploadOutlined />}>Enviar arquivo</Button>
						</Upload>

						{data?.fileURL ? (
							<Button
								style={{ marginLeft: 10 }}
								type="primary"
								onClick={() => {
									window.open(data.fileURL);
								}}
								icon={<FileOutlined />}
							>
								Ver arquivo
							</Button>
						) : null}
					</div>
				</Form.Item>
				<Form.Item label="Data Vencimento">
					<DatePicker
						style={{ width: "100%" }}
						format={"DD/MM/YYYY"}
						value={state?.dueDate ? state.dueDate : null}
						onChange={(e) => {
							setState((d) => {
								d.dueDate = e;
							});
						}}
					/>
				</Form.Item>
				<Form.Item label="Data Pagamento">
					<DatePicker
						style={{ width: "100%" }}
						format={"DD/MM/YYYY"}
						value={state?.paymentDate ? state.paymentDate : null}
						onChange={(e) => {
							setState((d) => {
								d.paymentDate = e;
							});
						}}
					/>
				</Form.Item>
				<Form.Item label="Valor">
					<InputNumber
						onChange={(e) => {
							setState((d) => {
								d.subtotal = e;
							});
						}}
						value={state?.subtotal ? state.subtotal : null}
					/>
				</Form.Item>
				<Form.Item label="Multa">
					<InputNumber
						onChange={(e) => {
							setState((d) => {
								d.fine = e;
							});
						}}
						value={state?.fine ? state.fine : null}
					/>
				</Form.Item>
				<Form.Item label="Juros">
					<InputNumber
						onChange={(e) => {
							setState((d) => {
								d.fees = e;
							});
						}}
						value={state?.fees ? state.fees : null}
					/>
				</Form.Item>
				<Form.Item label="Total">
					<InputNumber
						onChange={(e) => {
							setState((d) => {
								d.total = e;
							});
						}}
						value={state?.total ? state.total : null}
					/>
				</Form.Item>
				<Form.Item label="Status">
					<Select
						onChange={(e) => {
							setState((d) => {
								d.status = e;
							});
						}}
						options={[
							{ value: "pending", label: "Pendente" },
							{ value: "paid", label: "Pago" },
							{ value: "cancelled", label: "Cancelado" },
						]}
						value={state?.status ?? null}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
}

function ModalView({ visible, refetch, close, data }) {
	const loading = false;
	const save = null;

	const [status, setStatus] = useImmer(null);
	const [isEditing, setEditing] = useImmer(false);
	const [editState, setEditState] = useImmer({});

	const didMount = useRef(false);

	const [loadInstallment, response] = useLazyQuery(gql`
		query ActiveDebt($installment_id: String!) {
			ActiveDebt(installment_id: $installment_id) {
				cnpj
				business_name
				business_id
				user_id
				date
				installments_number
				current_installment_number
				totalAmount
				updatedAt
				status
			}
		}
	`);

	const [loadEntries, entries] = useLazyQuery(
		gql`
		query ActiveDebtEntries($installment_id: String!) {
			ActiveDebtEntries(installment_id: $installment_id) {
				_id,
				fileURL
				dueDate
				paymentDate
				fees
				fine
				subtotal
				installment_number
				total
				status
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	const [deleteEntry] = useMutation(
		gql`
		mutation ActiveDebtEntryDelete($installment_id: String!, $id: String!) {
			ActiveDebtEntryDelete(installment_id: $installment_id, id: $id)
		}
	`
	);

	const [updateStatus, { loading: updatingStatusLoading }] = useMutation(
		gql`
		mutation ActiveDebtStatus($installment_id: String!, $status: String!) {
			ActiveDebtStatus(installment_id: $installment_id, status: $status)
		}
	`
	);

	const [update, { loading: updatingLoading }] = useMutation(
		gql`
		mutation ActiveDebtUpdate($input: ActiveDebtInputUpdate, $installment_id: String) {
			ActiveDebtUpdate(input: $input, installment_id: $installment_id) {
				_id
			}
		}
	`
	);

	useEffect(() => {
		if (visible && data?.status) {
			setStatus(data.status);
		} else {
			setStatus("pending");
		}
		didMount.current = true;
	}, [data, visible]);

	useEffect(() => {
		if (status && visible && didMount.current) {
			updateStatus({
				variables: {
					installment_id: data._id,
					status,
				},
			})
				.then((res) => {
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					refetch();
				});
		}
	}, [status]);

	const [modal, setModal] = useImmer({
		visible: false,
	});

	const onClose = () => {
		close();
		setStatus(null);
	};

	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			type: "error",
			confirmButtonText: "OK",
		});
	};

	const updateAction = async () => {
		let payload = { ...editState };

		if (!payload.installments_number)
			return ErrorDialog("Por favor, insira o número de parcelas");
		if (!payload.date) return ErrorDialog("Por favor, informe data");
		if (!payload.totalAmount)
			return ErrorDialog("Por favor, informe o valor total");

		payload.date = payload.date.toISOString();
		payload.installments_number = payload.installments_number.toString();

		console.log(payload);
		let res = await update({
			variables: {
				installment_id: data?._id,
				input: { ...payload },
			},
		});

		if (res?.data?.ActiveDebtUpdate?._id) {
			response.refetch();
			setEditState({});
			setEditing(false);
		} else {
			ErrorDialog(
				"Não foi possível atualizar no momento. Tenta novamente mais tarde."
			);
		}
	};

	const removeEntry = (row) => {
		console.log(row);

		Swal.fire({
			title: "Remover parcela",
			text: "Tem certeza que deseja remover esta parcela? Esta ação não pode ser desfeita.",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sim",
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let res = await deleteEntry({
						variables: {
							installment_id: data._id,
							id: row._id,
						},
					});

					if (res?.data?.ActiveDebtEntryDelete) {
						entries.refetch();
						response.refetch();
						refetch();
					} else {
						ErrorDialog("Não foi possível completar a solicitação.");
					}
				} catch (error) {
					ErrorDialog(
						error?.message || "Não foi possível completar a solicitação."
					);
				}
			}
		});
	};

	const renderStatus = (status) => {

		if(status === 'paid') {
			return <Badge status="success" text={'Pago'} />
		}
		if(status === 'cancelled') {
			return <Badge status="error" text={'Cancelado'} />
		}
		return <Badge status="warning" text={'Pendente'} />
	}

	useEffect(() => {
		if (visible && data?._id) {
			loadInstallment({
				variables: {
					installment_id: data?._id,
				},
			});

			loadEntries({
				variables: {
					installment_id: data._id,
				},
			});
		}
	}, [visible, data]);

	return (
		<Modal
			title="Parcelamento"
			visible={visible}
			onOk={save}
			width={1280}
			onCancel={onClose}
			footer={null}
		>
			<Form layout="vertical">
				{response?.loading ? <Spin /> : null}

				{!response?.loading && response?.data?.ActiveDebt ? (
					<>
						<div>
							<Descriptions
								title="Sobre o Parcelamento"
								bordered
								layout="vertical"
								extra={
									!isEditing ? (
										<Button
											type="primary"
											onClick={() => {
												let { totalAmount, installments_number, date, status } =
													response?.data?.ActiveDebt;

												date = moment(date);

												setEditState({
													totalAmount,
													installments_number,
													date,
													status,
												});

												setEditing(true);
											}}
										>
											Editar
										</Button>
									) : (
										<Button
											type="default"
											onClick={() => {
												setEditState({});
												setEditing(false);
											}}
										>
											Cancelar
										</Button>
									)
								}
							>
								<Descriptions.Item label="Empresa" span={2}>
									{response?.data?.ActiveDebt?.business_name ?? "-"}
								</Descriptions.Item>
								<Descriptions.Item label="CNPJ">
									{response?.data?.ActiveDebt?.cnpj
										? Mask(
												response?.data?.ActiveDebt.cnpj,
												"99.999.999/9999-99"
										  )
										: "-"}
								</Descriptions.Item>
								
								<Descriptions.Item label="Parcela Atual">
									{response?.data?.ActiveDebt?.current_installment_number ??
										"-"}
								</Descriptions.Item>
								<Descriptions.Item label="Parcelas">
									{isEditing ? (
										<Input
											value={editState.installments_number}
											type="number"
											min={1}
											max={60}
											onChange={(e) => {
												setEditState((d) => {
													d.installments_number = e.target.value;
												});
											}}
										/>
									) : (
										response?.data?.ActiveDebt?.installments_number ?? "-"
									)}
								</Descriptions.Item>
								<Descriptions.Item label="Data">
									{isEditing ? (
										<DatePicker
											value={editState.date}
											style={{ width: "100%" }}
											format={"DD/MM/YYYY"}
											allowClear={false}
											onChange={(e) => {
												setEditState((d) => {
													d.date = e;
												});
											}}
										/>
									) : data?.date ? (
										moment(response?.data?.ActiveDebt.date).format(
											"DD/MM/YYYY"
										)
									) : (
										"-"
									)}
								</Descriptions.Item>
								
								<Descriptions.Item label="Valor Total" span={2}>
									{isEditing ? (
										<InputNumber
											value={editState?.totalAmount}
											onChange={(e) => {
												setEditState((d) => {
													d.totalAmount = e;
												});
											}}
										/>
									) : response?.data?.ActiveDebt?.totalAmount ? (
										response?.data?.ActiveDebt.totalAmount.toLocaleString(
											"pt-BR",
											{
												style: "currency",
												currency: "BRL",
											}
										)
									) : (
										"-"
									)}
								</Descriptions.Item>
								
								<Descriptions.Item label="Status">
									{isEditing ? (
										<Select
											options={[
												{ value: "pending", label: "Pendente" },
												{ value: "cancelled", label: "Cancelado" },
												{ value: "paid", label: "Pago" },
											]}
											value={editState.status}
											onChange={(e) => {
												setEditState(d => {
													d.status = e;
												})
											}}
											style={{ width: "100%" }}
										/>
									) : (
										<div>{renderStatus(response?.data?.ActiveDebt?.status)}</div>
									)}
								</Descriptions.Item>
							</Descriptions>

							{isEditing ? (
								<div style={{ textAlign: "right", marginTop: 20 }}>
									<Button type="primary" onClick={updateAction} loading={updatingLoading} disabled={updatingLoading}>
										Salvar
									</Button>
								</div>
							) : null}

							<div style={{ marginTop: 30, marginBottom: 20 }}>
								{entries?.data?.ActiveDebtEntries?.length ? (
									<div style={{ margin: "20px 0" }}>
										<Divider />
										<h3>Parcelas</h3>
										<Table
											dataSource={entries?.data?.ActiveDebtEntries}
											rowKey="_id"
											pagination={false}
											columns={[
												{
													title: "Parcela",
													dataIndex: "index",
													key: "index",
													render: (value, row, index) => {
														let status;
														switch (row.status) {
															case "paid":
																status = "success";
																break;
															case "cancelled":
																status = "error";
																break;
															default:
																status = "warning";
																break;
														}

														return (
															<div>
																{row?.installment_number ?? "-"}
																<Badge
																	status={status}
																	style={{ marginLeft: 10 }}
																/>
															</div>
														);
													},
												},
												{
													title: "Data Vencimento",
													dataIndex: "dueDate",
													key: "dueDate",
													render: (value) => {
														return value
															? moment(value).format("DD/MM/YYYY")
															: "-";
													},
												},
												{
													title: "Data Pagamento",
													dataIndex: "paymentDate",
													key: "paymentDate",
													render: (value) => {
														return value
															? moment(value).format("DD/MM/YYYY")
															: "-";
													},
												},
												{
													title: "Valor",
													dataIndex: "subtotal",
													key: "subtotal",
													render: (value) => {
														return value ? MaskPrice(value) : "-";
													},
												},
												{
													title: "Juros",
													dataIndex: "fees",
													key: "fees",
													render: (value) => {
														return MaskPrice(value);
													},
												},
												{
													title: "Multa",
													dataIndex: "fine",
													key: "fine",
													render: (value) => {
														return MaskPrice(value);
													},
												},
												{
													title: "Total",
													dataIndex: "total",
													key: "total",
													render: (value) => {
														return value ? MaskPrice(value) : "-";
													},
												},
												{
													title: "Ação",
													dataIndex: "action",
													key: "action",
													// width: 240,
													render: (value, row) => {
														return (
															<div>
																<Tooltip title="Editar">
																	<Button
																		shape="circle"
																		icon={<EditOutlined />}
																		onClick={() => {
																			setModal({
																				visible: true,
																				data: row,
																			});
																		}}
																	/>
																</Tooltip>
																<Tooltip title="Remover">
																	<Button
																		shape="circle"
																		style={{ marginLeft: 8 }}
																		icon={<DeleteOutlined />}
																		onClick={() => {
																			removeEntry(row);
																		}}
																	/>
																</Tooltip>
																{row.fileURL && !row?.paymentDate ? (
																	<Button
																	style={{ marginLeft: 8 }}
																	shape="round"
																	type="primary"
																	icon={<FileOutlined />}
																	onClick={() => {
																		window.open(row.fileURL);
																	}}
																>Baixar guia</Button>
																) : null}
															</div>
														);
													},
												},
											]}
										/>
									</div>
								) : null}

								<Button
									style={{ width: "100%" }}
									size="large"
									icon={<PlusOutlined />}
									onClick={() => {
										setModal({
											visible: true,
										});
									}}
								>
									Adicionar Parcela
								</Button>
							</div>
						</div>
					</>
				) : null}
			</Form>

			{data ? (
				<InstallmentModal
					{...modal}
					installment_id={data._id}
					refetch={() => {
						entries.refetch();
						response.refetch();
						refetch();
					}}
					currentEntries={entries?.data?.ActiveDebtEntries}
					close={() => {
						setModal({
							visible: false,
						});
					}}
				/>
			) : null}
		</Modal>
	);
}

export default ModalView;
