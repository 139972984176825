import React, { useState, useEffect, useRef } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import Mask from "functions/mask";
import {
	Col,
	Row,
	Layout,
	Table,
	Typography,
	Button,
	Form,
	Tooltip,
	Badge,
	DatePicker,
} from "antd";
import MaskedInput from "antd-mask-input";
import { useImmer } from "use-immer";
import Modal from "./ModalInstallment";
import ModalView from "./ModalInstallmentView";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import Swal from "sweetalert2";

const { Content } = Layout;
const { Title } = Typography;

function DAS() {
	const [modal, setModal] = useImmer({
		visible: false,
	});
	const [modalView, setModalView] = useImmer({
		visible: false,
	});
	const [filters, setFilters] = useImmer({});

	const [deleteInstallment] = useMutation(gql`
		mutation ActiveDebtDelete($installment_id: String!) {
			ActiveDebtDelete(installment_id: $installment_id)
		}
	`);

	const { data, loading, error, refetch } = useQuery(gql`
		query ActiveDebts($filter: ActiveDebtsFilter) {
			ActiveDebts(filter: $filter) {
				_id
				cnpj
				business_name
				business_id
				user_id
				date
				installments_number
				current_installment_number
				totalAmount
				updatedAt
				status
			}
		}
	`, {
		fetchPolicy: 'no-cache',
		notifyOnNetworkStatusChange: true
	});

	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			type: "error",
			confirmButtonText: "OK",
		});
	};

	console.log(data);

	const removeInstallment = (row) => {
		Swal.fire({
			title: "Remover parcelamento",
			text: "Tem certeza que deseja remover este parcelamento? Esta ação não pode ser desfeita. Todos os arquivos serão deletados.",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sim",
		}).then(async (result) => {
			if (result.isConfirmed) {

				console.log(row);
				
				try {
					let res = await deleteInstallment({
						variables: {
							installment_id: row._id
						}
					});

					if(res?.data?.ActiveDebtDelete) {
						refetch()
					} else {
						ErrorDialog("Não foi possível completar a solicitação.");
					}
				} catch (error) {
					ErrorDialog(error?.message || "Não foi possível completar a solicitação.");
				}

			}
		});
	}

	const columns = [
		{
			title: "Empresa",
			dataIndex: "business_name",
			key: "business_name",
			render: (value, row) => {
				return row.user_id ? (
					<a href={`user/${row.user_id}`}>{value}</a>
				) : (
					value
				);
			},
		},
		{
			title: "CNPJ",
			dataIndex: "cnpj",
			key: "cnpj",
			render: (value) => {
				return value ? Mask(value, "99.999.999/9999-99") : "-";
			},
		},
		{
			title: "Data",
			dataIndex: "date",
			key: "date",
			render: (value) => {
				return value ? moment(value).format('DD/MM/YYYY') : '-';
			},
		},
		{
			title: "Parcelas",
			dataIndex: "installments_number",
			key: "installments_number",
			render: (value) => {
				return value;
			},
		},
		{
			title: "Parcela Atual",
			dataIndex: "current_installment_number",
			key: "current_installment_number",
			render: (value) => {
				return value;
			},
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (value) => {
				if(value === 'paid') return (<>
					<Badge status="success" /> Pago
				</>);
				if(value === 'cancelled') return (<>
					<Badge status="error" /> Cancelado
				</>);
				return (<>
					<Badge status="warning" />
					Pendente
				</>);
			},
		},
		{
			title: "Valor Total",
			dataIndex: "totalAmount",
			key: "totalAmount",
			render: (value) => {
				return value
					? value.toLocaleString("pt-BR", {
							style: "currency",
							currency: "BRL",
					  })
					: "-";
			},
		},
		{
			title: "Ações",
			dataIndex: "actions",
			key: "actions",
			width: 150,
			render: (val, row) => {
				return (
					<div>
						<Tooltip title="Ver">
							<Button
								shape="circle"
								icon={<EditOutlined />}
								style={{ marginRight: 8 }}
								onClick={() => {
									setModalView({
										visible: true,
										data: row
									})
								}}
							/>
						</Tooltip>
						<Tooltip title="Remover">
							<Button
								shape="circle"
								icon={<DeleteOutlined />}
								onClick={() => {
									removeInstallment(row)
								}}
							/>
						</Tooltip>
					</div>
				);
			},
		},
	];

	const filterAction = () => {
		refetch({
			filter: filters
		})
	};


	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>Parcelamento de Dívida Ativa</Title>
					<Button
						type="primary"
						onClick={() => {
							setModal((state) => {
								return {
									visible: true,
									data: {},
								};
							});
						}}
					>
						Novo Parcelamento
					</Button>
				</div>

				<div style={{ marginBottom: "30px" }}>
					<Form>
						<Row gutter={20}>
							<Col span={12}>
								<Form.Item>
									<MaskedInput
										mask={"11.111.111/1111-11"}
										placeholder="Buscar por CNPJ"
										value={filters?.cnpj ?? ""}
										onChange={(e) => {
											setFilters((d) => {
												d.cnpj = e.target.value;
											});
										}}
									/>
								</Form.Item>
							</Col>
							<Col flex="auto">
								<Form.Item>
									<DatePicker
										format={"MMMM/YYYY"}
										value={filters?.period}
										onChange={(e) => {
											setFilters((d) => {
												d.period = e;
											});
										}}
										placeholder="Selecione um mês/ano"
										style={{ width: "100%" }}
										picker="month"
										disabledDate={(current) => {
											const start = moment("2018-01-01", "YYYY-MM-DD");
											return current < start || current > moment();
										}}
									/>
								</Form.Item>
							</Col>
							<Col>
								<Button onClick={filterAction}>Filtrar</Button>
							</Col>
						</Row>
					</Form>
				</div>

				<div className="dashboard">
					{loading ? <Loading /> : null}

					{!loading && !error && data ? (
						<div>
							<Table
								rowKey="_id"
								dataSource={
									data?.ActiveDebts?.length ? data.ActiveDebts : []
								}
								columns={columns}
							/>
						</div>
					) : null}

					{!loading && error ? <Error refetch={() => refetch()} /> : null}
				</div>

				<Modal
					{...modal}
					refetch={refetch}
					close={() => {
						setModal({
							visible: false,
						});
					}}
				/>
				<ModalView
					{...modalView}
					refetch={refetch}
					close={() => {
						setModalView({
							visible: false,
						});
					}}
				/>
			</Content>
		</Layout>
	);
}

export default DAS;
