import React, { useState, useEffect } from "react";
import * as Queries from "../Queries";
import { useQuery, useMutation } from "@apollo/client";

import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import _ from "lodash";

import {
	Col,
	Row,
	Layout,
	Table,
	Typography,
	Button,
	Card,
	Form,
	Input,
	Select,
	DatePicker,
	Upload,
	notification,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";

import dayjs from "dayjs";
import { statuses } from "../Misc";
import Estados from "functions/states";

import moment from "moment";

const { Content } = Layout;
const { Title } = Typography;

function Document({ id, name, label, data, business_id }) {
	const [upload] = useMutation(Queries.DOCUMENT_UPLOAD);
	const [state, setState] = useState(null);
	const [list, setList] = useState([]);

	useEffect(() => {
		if (data) setState(data);
	}, []);

	const uploadFile = async (e) => {
		const { data, error } = await upload({
			variables: {
				input: {
					name: name,
					file: e.file,
				},
				uid: id,
				business_id
			},
		});

		if (error) {
			notification.error({
				message: "Erro",
				description:
					"Não foi possível enviar o arquivo. Por favor, tente novamente mais tarde.",
			});
		} else {
			setState(data.BusinessDocument);
			setList([]);
		}
	};

	const handleChange = ({ fileList }) => {
		setList(fileList);
	};

	const open = () => {
		window.open(state.path);
	};

	return (
		<Form.Item name={name} label={label}>
			{state ? (
				<div style={{ marginBottom: 20 }}>
					<span style={{ marginRight: "10px" }}>{state.name}</span>
					<Button type="primary" onClick={open}>
						Ver
					</Button>
				</div>
			) : null}

			<Upload
				name={name}
				customRequest={uploadFile}
				onChange={handleChange}
				fileList={list}
			>
				<Button icon={<UploadOutlined />}>Enviar</Button>
			</Upload>
		</Form.Item>
	);
}

//
// Main View
//

function Documents({ id, business_id }) {
	const [state, setState] = useState(null);

	const { loading, data, error, refetch } = useQuery(Queries.DOCUMENTS, {
		fetchPolicy: "no-cache",
		variables: {
			id: id,
			business_id,
		},
	});

	useEffect(() => {
		if (data)
			setState(data?.Business?.documents ? data?.Business?.documents : {});
	}, [data]);

	const getValue = (path) => {
		return _.get(state, path);
	};

	const setValue = (path, value) => {
		setState((state) => {
			_.set(state, path, value);
			return state;
		});
	};

	return (
		<Card title="Documentos">
			{loading ? <Loading /> : null}

			{!loading && error ? <Error refetch={refetch} /> : null}

			{state && !loading && !error ? (
				<Form layout="vertical">
					<Row gutter={20}>
						<Col span={12}>
							<Document
								label={"Alvará"}
								data={state.alvara}
								name={"alvara"}
								id={id}
								business_id={business_id}
							/>
						</Col>

						<Col span={12}>
							<Document
								label={"Cartão CNPJ"}
								data={state.cartao_cnpj}
								name={"cartao_cnpj"}
								id={id}
								business_id={business_id}
							/>
						</Col>

						<Col span={12}>
							<Document
								label={"RG"}
								data={state.rg}
								name={"rg"}
								id={id}
								business_id={business_id}
							/>
						</Col>

						<Col span={12}>
							<Document
								label={"CPF"}
								data={state.cpf}
								name={"cpf"}
								id={id}
								business_id={business_id}
							/>
						</Col>

						<Col span={12}>
							<Document
								label={"CNH"}
								data={state.cnh}
								name={"cnh"}
								id={id}
								business_id={business_id}
							/>
						</Col>

						<Col span={12}>
							<Document
								label={"Título de Eleitor"}
								data={state.titulo_eleitor}
								name={"titulo_eleitor"}
								id={id}
								business_id={business_id}
							/>
						</Col>

						<Col span={12}>
							<Document
								label={"IPTU"}
								data={state.iptu}
								name={"iptu"}
								id={id}
								business_id={business_id}
							/>
						</Col>
					</Row>
				</Form>
			) : null}
		</Card>
	);
}

export default Documents;
