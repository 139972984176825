import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

function Loading() {
	return (
		<div className="loading-component-view">
			<LoadingOutlined style={{fontSize: '50px'}}/>
		</div>
	);
}

export default Loading;