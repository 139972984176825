import { Select, Spin } from "antd";
import { useLazyQuery } from "@apollo/client";
import debounce from "functions/debounce";
import { gql } from "@apollo/client";
import { useEffect, useRef } from "react";


const {Option} = Select;

const USER_SEARCH = gql`
  query UserSearch($term: String, $status: Int) {
    UserSearch(term: $term, status: $status) {
      _id
      name
      business_id
      business_name
    }
  }
`;

const UserSearch = ({onChange, value, disabled, additionalProps, additionalVariables}) => {



  const el = useRef(null);  
   const [search, { loading, data: response }] = useLazyQuery(
     USER_SEARCH
   );

  const onSearch = debounce((e) => searchAction(e), 500);

  const searchAction = async (term) => {
    await search({
      variables: {
        term,
        ...additionalVariables
      },
    });
  };


  return (
    <Select
      ref={el}
      disabled={disabled}
      showSearch
      showArrow={false}
      filterOption={false}
      onSearch={onSearch}
      notFoundContent={loading ? <Spin size="small" /> : null}
      onChange={(value, option) => {
        onChange(value, option.data);
      }}
      placeholder="Buscar usuários"
      value={value ?? null}
      {...additionalProps}
    >
      {response?.UserSearch?.length &&
        response.UserSearch.map((user) => {
          return (
            <Option key={user._id} value={user._id} data={user}>
              {user.name}
              {(user.business_name && user.business_id) && (
                <span className="select-min-option">{user.business_name}</span>
              )}
            </Option>
          );
        })}
    </Select>
  );
};


export default UserSearch;